*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

.ui.button {
  /* text-transform: lowercase !important; */
  font-weight: 300;
}

/* .ui.dimmer {
  display: flex !important;
}

.ui.modal {
  margin-top: 0;
} */

.ui.grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.middle.aligned.column {
  padding: 0 !important;
}

input[type='file'] {
  width: 100%;
}
